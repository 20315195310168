
/* Container for the "or" text */
.or-container {
  position: relative;;
  text-align: center;
  width: 100%;
  align-items: center;
}

/* Horizontal line */
.or-container::before,
.or-container::after {
  content: "";
  display: inline-block;
  width: 44%;
  border-top: 1px solid #ccc;
  vertical-align: middle;
}

/* "Or" text style */
.or-text {
  display: inline-block;
  padding: 0 ;
  font-weight: lighter;
  color: #555;
  position: relative;
  top: -5px; /* Adjust as needed */
}
.required::after {
  content: "*";
  color: rgb(199, 25, 25);
  margin-left: 3px;
  font-size: 12px;
}


.outlet-container.sticky {
  padding-top: 100px;
}
/*Profile menu hover */
.profile-menus  {
  display: none;
}
.profile-menu:hover .profile-menus {
  display: flex;
}
.mobile-nav {
  z-index: 10;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield;
}

.outlet::-webkit-scrollbar {
  display: none;
}
input#file-button {
  padding: 0;
}
input#file-button::-webkit-file-upload-button {
  padding: 7px 15px;
  border: none;
  outline: none;
  cursor: pointer;
  background: rgba(130, 130, 129, 0.597);
  color: #F5F5F5;
}
input#file-button::-webkit-file-upload-button:hover {
  transition: 0.3s;
  background: rgba(111, 64, 16, 0.9);
}

/* Global Table styles */

.store-action-menu:hover .store-menus {
  display: block;
}
.store-menus  {
  display: none;
}
.order-table  tbody td{
  max-width: 120px;
}
/* React Date Picker */

@keyframes search {
  0% {
    width: 0px;
  }
  100% {
    width: 300px;
  }
}
/* Pagination Styles*/

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  gap: 0.5rem;
}

.page-item {
  list-style: none;
}

.page-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 1.3rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.page-link:hover {
  background-color:rgba(58, 38, 24, 0.3);
}

.activepage .page-link {
  background-color: #3a2618;
  color: white;
}

.disabledpage.page-link-next, .disabledpage .page-link-prev {
  background-color: #3a2618;
  cursor: not-allowed;
}

/* Form Modal*/
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0 15px 15px 0;
  
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0 15px 15px 0;
}

/* Handle on hover */
l::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*Flickity*/
.carousel-cell img {
  transition: transform 0.8s ease; 
}

.carousel-cell.is-selected {
  opacity: 1;
  filter: none;
  transform: scale(1.2)
}
.brand_slider .carousel-cell:not(.is-selected) {
  filter: blur(5px);
 
}
.brand_slider .carousel-cell.is-selected img {
  transform: scale(1.2)
}
.brand_slider  .carousel-cell:not(.is-selected)  img{
  transform: scale(0.5); 
}


.line-after-brown::after {
  content: '';
  display: block;
  position: absolute;
  width:100px;
  height: 2px;
  background-color: #3a2618; 
  margin-top: 8px; 
}
.line-center.line-after-brown::after {
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 780px) {
  .line-after-brown::after {
    left: 50%;
    transform: translateX(-50%);
  }
}

.mobile-nav {
  z-index: 0;
}

.mobile-menus {
  z-index: 3;
}
.filter-menu {
  z-index: 9999999;
}

/* position dots up a bit */
.flickity-page-dots {
  bottom: -22px;
}
/* dots are lines */
.flickity-page-dots .dot {
  height: 4px;
  width: 40px;
  margin: 0;
  border-radius: 0;
}