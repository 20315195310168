@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'react-datepicker/dist/react-datepicker.css';
@import 'flickity/dist/flickity.min.css';
@import 'easymde/dist/easymde.min.css';
@layer base {
    body, html {
        @apply font-serif m-0 p-0 bg-primary text-secondary scroll-smooth
    }
    h1 {
        @apply text-[2.5rem] md:text-[3rem] font-bold
    }
    h2 {
        @apply text-[1rem] text-secondary
    }
    p{
        @apply text-[1.2rem] text-secondary
    }
    li a.active {
        @apply pb-2 border-b-[2px] border-secondary
    }
    ul.my_account li a.active {
        @apply  font-bold
    }
    .navbar-container {
        @apply w-full z-[1]
     }
     .home-navbar.navbar-container .profile-menus  a {
         @apply text-secondary
     }
    .home-navbar.navbar-container h1 , .home-navbar.navbar-container a, .home-navbar.navbar-container span {
        @apply text-primary
    }
    .navbar-container.sticky  {
       @apply   fixed top-0 left-0 w-full 
    }
    .navbar-container.sticky h1 , .navbar-container.sticky a , .navbar-container.sticky span, .navbar-container.sticky div  {
        @apply text-secondary 
    }
    .navbar-container.sticky .navbar-wrapper  {
        @apply h-[70px] backdrop-blur-xl bg-primary/70
     }
   textarea, select {
        @apply w-full px-[15px] text-secondary py-[7px] bg-transparent border-solid text-[18px] border-[2px] border-secondary/20 focus:border-secondary  outline-none placeholder:text-secondary transition-all duration-300
    }
    input {
        @apply  w-full px-[15px] text-secondary py-[7px] bg-transparent border-solid text-[18px] border-[2px] border-secondary/20 focus:border-secondary  outline-none placeholder:text-secondary/50 transition-all duration-300
    }
    .input-container {
        @apply flex flex-col gap-3 items-start 
    }
    .input-div {
        @apply  w-full text-secondary h-[45px]  bg-transparent border-solid border-[2px] border-secondary/20 hover:border-secondary outline-none placeholder:text-secondary transition-all duration-300 flex items-center 
    }.input-container input {
        @apply  w-full px-[15px] text-secondary py-[7px] bg-transparent border-none text-[18px]  outline-none placeholder:text-secondary/50 transition-all duration-300 font-bold
    }
    .input-div button {
        @apply w-[50px] h-full hover:bg-accent/30 text-center flex items-center justify-center
    }
    select {
        @apply cursor-pointer py-[10px] px-[15px]
    }
    button, select, a {
        @apply transition duration-300 text-secondary font-serif rounded-[0px]
    }
    img {
        @apply w-full h-full object-cover
    }
    table {
        @apply  w-full transition-all duration-300
    }
    table:not(.cart-table) thead {
        @apply bg-accent/30 text-secondary font-semibold
    }
    table:not(.cart-table) td {
        @apply text-[19px] px-[10px] py-[7px]  transition-all duration-300
    }
    table:not(.cart-table) tbody tr {
        @apply  border-b-[1px] border-secondary/10  transition-all duration-300
    }
    .footer h1, .footer h2, .footer li , .footer p {
        @apply text-primary
    }
    .shop-filter {
        @apply z-[10] fixed bg-red-200
    }
    .weight-radio-button {
        @apply relative inline-block py-[8px] px-[20px] cursor-pointer rounded-[30px] text-secondary transition-all transform duration-300 border-[2px] border-[#ddd]
    }
    .weight-radio-button.selected {
        @apply bg-secondary/90 text-primary border-secondary
    }

    td ul li a {
        @apply bg-none hover:bg-secondary/5 transition-none duration-300
    }
}
